/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites by ZURB
 * Version 6.4.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.single-most_publication #inner-content {
  max-width: 55rem;
}
.single-most_publication #inner-content .journal-date {
  color: #6ba8db;
  margin-bottom: 1rem;
}
.single-most_publication #inner-content .journal-date .journal {
  font-style: italic;
}
.single-most_publication #inner-content .journal-date .sep {
  margin: 0 0.5rem;
}
.single-most_publication #inner-content h1 {
  margin: 0 !important;
  color: #8d8c8c !important;
  font-size: 1.75rem !important;
}
.single-most_publication #inner-content .sep-main {
  margin-top: 2rem;
  margin-bottom: 1rem;
  background-color: #8d8c8c;
  height: 2px;
}
.single-most_publication #inner-content .sep-main.citation {
  margin-top: 1rem;
}
.single-most_publication #inner-content .authors {
  margin-bottom: 2rem;
  font-size: 1rem;
}
.single-most_publication #inner-content .authors .primary {
  font-weight: bold;
}
.single-most_publication #inner-content .authors .sep {
  margin: 0 0.5rem;
}
.single-most_publication #inner-content .url-doi {
  margin: 4rem 0;
  display: block;
}
.single-most_publication #inner-content .url-doi .group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.single-most_publication #inner-content .url-doi .group:last-child {
  margin-bottom: 0;
}
.single-most_publication #inner-content .url-doi .group .url {
  margin-bottom: 0.5rem;
}
.single-most_publication #inner-content .url-doi .group .doi {
  color: #c10230;
  font-size: 1rem;
}
.single-most_publication #inner-content .url-doi .group .doi b {
  color: #0a0a0a;
}
@media screen and (min-width: 40em) {
  .single-most_publication #inner-content .url-doi .group {
    flex-direction: row;
    align-items: center;
  }
  .single-most_publication #inner-content .url-doi .group .url {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
.single-most_publication #inner-content .publisher > img {
  height: 4rem;
}
.single-most_publication #inner-content .citation {
  margin-bottom: 2rem;
}
.single-most_publication #inner-content .citation .content {
  font-size: 1rem;
  font-style: italic;
}
.single-most_publication #inner-content .classifications {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}
.single-most_publication #inner-content .classifications .button {
  margin-bottom: 1rem;
}
.single-most_publication #inner-content .classifications .button:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 40em) {
  .single-most_publication #inner-content .classifications {
    flex-direction: row;
  }
  .single-most_publication #inner-content .classifications .button {
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .single-most_publication #inner-content .classifications .button:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}
.single-most_publication #inner-content .keywords {
  margin-bottom: 4rem;
  font-size: 1rem;
}
.single-most_publication #inner-content .keywords > span {
  text-transform: uppercase;
  color: #6ba8db;
  font-weight: 700;
}
.single-most_publication #inner-content .keywords > a {
  color: #8d8c8c;
  text-decoration: none;
}
.single-most_publication #inner-content .keywords > a:hover, .single-most_publication #inner-content .keywords > a:focus {
  text-decoration: underline;
}