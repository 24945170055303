@import '../../../global/styles/lib';

$main-gutter: 2rem;
$double-gutter: 4rem;
$half-gutter: 1rem;
$quarter-gutter: 0.5rem;

.single-most_publication {
    #inner-content {
        max-width: $entry-content-width;

        .journal-date {
            color: $most-blue;
            margin-bottom: $half-gutter;
            .journal {
                font-style: italic;
            } // .journal
            .sep {
                margin: 0 $quarter-gutter;
            } // .sep
        } // .journal-date

        h1 {
            margin: 0 !important;
            color: $most-gray !important;
            font-size: 1.75rem !important;
        } // h1

        .sep-main {
            margin-top: $main-gutter;
            margin-bottom: $half-gutter;
            background-color: $most-gray;
            height: 2px;
            &.citation {
                margin-top: $half-gutter;
            } // &.citation
        } // .sep-main

        .authors {
            margin-bottom: $main-gutter;
            font-size: 1rem;
            .primary {
                font-weight: bold;
            } // .primary
            .sep {
                margin: 0 $quarter-gutter;
            } // .sep
        } // .authors

        .url-doi {
            margin: $double-gutter 0;
            display: block;
            .group {
                display: flex;
                flex-direction: column;
                margin-bottom: $half-gutter;

                &:last-child {
                    margin-bottom: 0;
                } // &:last-child

                .url {
                    margin-bottom: 0.5rem;
                } // .url
                
                .doi {
                    color: $most-red;
                    font-size: 1rem;
                    b {
                        color: $black;
                    } // b
                } // .doi

                @include breakpoint(medium up) {
                    flex-direction: row;
                    align-items: center;
                    .url {
                        margin-bottom: 0;
                        margin-right: 1rem;
                    } // .url
                } // medium up

            } // .group

        } // .url-doi

        .publisher {
            & > img {
                height: 4rem;
            } // & > img
        } // .publisher

        .citation {
            margin-bottom: $main-gutter;
            .content {
                font-size: 1rem;
                font-style: italic;
            } // .content
        } // .citation

        .classifications {
            margin-bottom: $main-gutter;
            display: flex;
            flex-direction: column;

            .button {
                margin-bottom: $half-gutter;
                &:last-child {
                    margin-bottom: 0;
                } // &:last-child
            } // .button

            @include breakpoint(medium up) {
                flex-direction: row;
                
                .button {
                    margin-right: $half-gutter;
                    margin-bottom: 0;
                    &:last-child {
                        margin-right: 0;
                        margin-bottom: 0;
                    } // &:last-child
                } // .button
            } // medium up

        } // .classifications

        .keywords {
            margin-bottom: $double-gutter;
            font-size: 1rem;
            & > span {
                text-transform: uppercase;
                color: $most-blue;
                font-weight: 700;
            } // & > span

            & > a {
                color: $most-gray;
                text-decoration: none;
                #{$hovfoc} {
                    text-decoration: underline;
                } // hover focus
            } // & > a

        } // .margin-bottom

    } // #inner-content
} // .single-most_publication